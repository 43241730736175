import $, { each } from 'jquery';

/*
Add <meta mode="demo"> to active demo mode
*/

if(window.location.href.indexOf("https://imac.local:5757") >= 0)
{
    if(document.head.querySelector("[mode~=demo]") == null)
    {
        const navs = {
            'Tailwind': 'https://tailwindcss.com/docs/customizing-colors',
            'DaisyUI': 'https://daisyui.com/components/button/',
            'Flowbite': 'https://flowbite.com/docs/components/carousel/',
            'Alpine': 'https://alpinejs.dev/start-here',
            'System': 'https://bigwinfc.com/',
            'OG': 'https://ufanance.com/',
            'UX1': 'https://panama888.co',
            'UX2': 'https://newyork888s.com',
            'TF1': 'https://pixner.net/bitbetio/main/index.html',
            'PNG1': 'https://www.pngwing.com',
            'PNG2': 'https://www.pngaaa.com',
            'PNG3': 'https://toppng.com',
            'CODEPEN': 'https://codepen.io/trending',
        };
        
        let devnav = '<div class="absolute py-3 bg-opacity-50 w-full top-0 left-0 z-50 text-gray-400 text-xs opacity-10 transition-opacity hover:opacity-100"><div class="block max-w-5xl text-center mx-auto">';
        
        $.each(navs, function(key, value) {
            devnav += '<div class="inline-block"><a class="transition-all p-2 hover:text-red-400" href="'+value+'" target="_blank">'+key+'</a></div>';
        });
        
        devnav += '</div></div>';
        
        $("html > body").append(devnav);
        $("html > head").prepend('<meta name="dev-mode">');
    
        $('#loading').remove();
    
        console.log('[Dev Mode]', true);
    }
}

$('[mode~=demo]').remove();