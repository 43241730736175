window.promos = function () {
    return {
        curr: 1, total: 0,
        init() {
            const cards = $(this.$el).find('.promo-card');

            window.promocurr = 1;
            this.curr = 1;
            this.total = cards.length;
            
            if(cards.length < 4)
                $('#promos-section').addClass('less-promos');

            if(cards.length == 2)
                $('#promos-section').addClass('double-promos');
            else if(cards.length == 1)
                $('#promos-section').addClass('single-promo');

            cards.each(function() {
                $(this).attr('id', "promo-" + window.promocurr++);
            });

            this.curr = 1;

            setInterval(() => {
                this.update();
            }, 1200);
        },
        update()
        {
            $(".promo-card.active").removeClass('active');

            $("#promo-"+(this.curr)).addClass('active');

            this.curr++;

            this.curr = this.curr > this.total ? 1 : this.curr ;
        },
		promo:
		{
			['x-on:click'](e)
			{
                let promo = $(e.target);

                if(typeof(promo.attr('id')) == 'undefined')
                    promo = promo.closest('.promo-card');

                window.dispatchEvent(new CustomEvent('promo-click', {
                    "detail": { promo: promo.attr('id') }
                }));
			},
		}
    }
}