window.activatablenav = function () {
    return {
        offset: 400,
        init() {
            this.update();
        },
        update() {
            const navbar = $(this.$el);
            const width = window.innerWidth;
            const scroll = Math.floor($(document).scrollTop());
            const offset = Math.floor((width > 720) ? this.offset : 50);

            if(scroll > offset)
                navbar.addClass('highlight');
            else
                navbar.removeClass('highlight');
        },
		activatablenav:
		{
			['x-on:scroll.window']()
			{
                this.update();
			},
			['x-on:resize.window']()
			{
                this.update();
			},
			['x-on:DOMContentLoaded.window']()
			{
                this.update();
			},
		}
    }
}