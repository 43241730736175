import $, { each } from 'jquery';
import Alpine from 'alpinejs';
import counterUp from 'counterup2';
import 'waypoints/lib/noframework.waypoints.min.js';
import 'waypoints/lib/shortcuts/inview.js';

import './dev';
import './animation';
import './confetti';
import './activatablenav';

import './promos';
import './promomodal';

window.$ = $;
window.Alpine = Alpine;

$(document).ready(function () 
{
    document.getElementById("year").innerHTML = new Date().getFullYear();

    $('.countup').each(function () 
    {
        const el = $(this)[0];
        new Waypoint({
            element: el,
            handler: function () 
            {
                counterUp(el);
                this.destroy();
            },
            offset: '100%',
        });
    });
});

Alpine.start();