window.promomodal = function () {
    return {
        init() {
            
        },
		promomodal:
		{
            ['x-on:promo-click.window'](e)
            {
                $('#promomodal-box #content').html($('#'+e.detail.promo+' .img-cont').html().trim());
                $('#promomodal').click();
            }
		}
    }
}